<template>
	<div class="main-view">
		<reset-password />
	</div>
</template>

<script>
import ResetPassword from '@/components/reset-password/reset-password'

export default {
	name: 'view-reset-password',
	components: { ResetPassword }
}
</script>

<style lang="stylus" scoped>

.main-view
	width 100%
	height 100%
	
</style>
